import React from 'react';

export const TSIcon = (
  <>
    <path
      d="M6.412 14.7143V10.0983H4.548V9.07429H9.516V10.0983H7.652V14.7143H6.412ZM12.599 14.8023C12.1563 14.8023 11.7377 14.7463 11.343 14.6343C10.9537 14.5223 10.6283 14.3703 10.367 14.1783L10.727 13.2023C10.9777 13.3783 11.263 13.517 11.583 13.6183C11.9083 13.7196 12.247 13.7703 12.599 13.7703C12.983 13.7703 13.2577 13.7116 13.423 13.5943C13.5937 13.4716 13.679 13.3196 13.679 13.1383C13.679 12.9836 13.6203 12.861 13.503 12.7703C13.3857 12.6796 13.1803 12.6023 12.887 12.5383L11.983 12.3463C10.975 12.133 10.471 11.6103 10.471 10.7783C10.471 10.421 10.567 10.109 10.759 9.84229C10.951 9.57029 11.2177 9.35963 11.559 9.21029C11.9057 9.06096 12.3057 8.98629 12.759 8.98629C13.1483 8.98629 13.5137 9.04229 13.855 9.15429C14.1963 9.26629 14.479 9.42363 14.703 9.62629L14.343 10.5383C13.9057 10.1916 13.375 10.0183 12.751 10.0183C12.415 10.0183 12.1537 10.085 11.967 10.2183C11.7857 10.3463 11.695 10.5143 11.695 10.7223C11.695 10.877 11.751 11.0023 11.863 11.0983C11.975 11.1943 12.1697 11.2716 12.447 11.3303L13.351 11.5223C14.3857 11.7463 14.903 12.253 14.903 13.0423C14.903 13.3943 14.807 13.7036 14.615 13.9703C14.4283 14.2316 14.1617 14.437 13.815 14.5863C13.4737 14.7303 13.0683 14.8023 12.599 14.8023Z"
      fill="black"
    />
    <rect
      x="0.571429"
      y="0.571429"
      width="16.8571"
      height="16.8571"
      rx="1.71429"
      stroke="black"
      strokeWidth="1.14286"
    />
  </>
);

export const ShellIcon = (
  <>
    <path
      d="M5 11.8574L8.5 8.4287L5 5"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13.5H14"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="black" />
  </>
);

export const JSIcon = (
  <>
    <path
      d="M6.008 14.7943L5.92 13.7863L6.536 13.7543C6.96267 13.7276 7.176 13.5036 7.176 13.0823V9.07429H8.416V13.0663C8.416 14.1223 7.856 14.6876 6.736 14.7623L6.008 14.7943ZM12.2084 14.8023C11.7657 14.8023 11.347 14.7463 10.9524 14.6343C10.563 14.5223 10.2377 14.3703 9.97637 14.1783L10.3364 13.2023C10.587 13.3783 10.8724 13.517 11.1924 13.6183C11.5177 13.7196 11.8564 13.7703 12.2084 13.7703C12.5924 13.7703 12.867 13.7116 13.0324 13.5943C13.203 13.4716 13.2884 13.3196 13.2884 13.1383C13.2884 12.9836 13.2297 12.861 13.1124 12.7703C12.995 12.6796 12.7897 12.6023 12.4964 12.5383L11.5924 12.3463C10.5844 12.133 10.0804 11.6103 10.0804 10.7783C10.0804 10.421 10.1764 10.109 10.3684 9.84229C10.5604 9.57029 10.827 9.35963 11.1684 9.21029C11.515 9.06096 11.915 8.98629 12.3684 8.98629C12.7577 8.98629 13.123 9.04229 13.4644 9.15429C13.8057 9.26629 14.0884 9.42363 14.3124 9.62629L13.9524 10.5383C13.515 10.1916 12.9844 10.0183 12.3604 10.0183C12.0244 10.0183 11.763 10.085 11.5764 10.2183C11.395 10.3463 11.3044 10.5143 11.3044 10.7223C11.3044 10.877 11.3604 11.0023 11.4724 11.0983C11.5844 11.1943 11.779 11.2716 12.0564 11.3303L12.9604 11.5223C13.995 11.7463 14.5124 12.253 14.5124 13.0423C14.5124 13.3943 14.4164 13.7036 14.2244 13.9703C14.0377 14.2316 13.771 14.437 13.4244 14.5863C13.083 14.7303 12.6777 14.8023 12.2084 14.8023Z"
      fill="black"
    />
    <rect
      x="0.571429"
      y="0.571429"
      width="16.8571"
      height="16.8571"
      rx="1.71429"
      stroke="black"
      strokeWidth="1.14286"
    />
  </>
);
