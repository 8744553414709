exports.components = {
  "component---src-components-screens-docs-screen-docs-screen-tsx": () => import("./../../../src/components/screens/DocsScreen/DocsScreen.tsx" /* webpackChunkName: "component---src-components-screens-docs-screen-docs-screen-tsx" */),
  "component---src-components-screens-index-screen-index-screen-js": () => import("./../../../src/components/screens/IndexScreen/IndexScreen.js" /* webpackChunkName: "component---src-components-screens-index-screen-index-screen-js" */),
  "component---src-components-screens-releases-screen-iframe-releases-screen-js": () => import("./../../../src/components/screens/ReleasesScreen/IframeReleasesScreen.js" /* webpackChunkName: "component---src-components-screens-releases-screen-iframe-releases-screen-js" */),
  "component---src-components-screens-releases-screen-releases-screen-js": () => import("./../../../src/components/screens/ReleasesScreen/ReleasesScreen.js" /* webpackChunkName: "component---src-components-screens-releases-screen-releases-screen-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-releases-iframe-404-js": () => import("./../../../src/pages/releases/iframe/404.js" /* webpackChunkName: "component---src-pages-releases-iframe-404-js" */)
}

